.container-fluid {
  //padding: 0 !important;
}

.layout-main {
  background-color: #171717;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  //padding: 3vh 3vw !important;
  //gap: 2vw;

  .title {
    //font-size: 3rem;
    font-size: 1.6rem !important;
    //color: #D66440;
    margin: 0;
    padding: 0;
  }

  .content {
    height: 76vh;
    // height: 72.5vh;
    overflow: hidden;
    background-color: #1f1f1f;
    //border-radius: 30px;

    // .display {
    //   // background: #2c2c2c;
    //   // overflow: hidden;
    //   //border: 1px solid #171717;
    // }

    .scale-icon {
      // width: 100px;
      width: 70px;
      // height: 100px;
      height: 70px;
      // transform: translate(-50px, -50px);
      transform: translate(-25px, -25px);
    }

    .return-icon {
      width: 70px;
      height: 70px;
      transform: translate(-110px, -25px);
      background-color: #171717;
      padding: 0.8rem;
      border-radius: 50%;

      &.full {
        transform: translate(-25px, -110px);
      }
    }
  }

  .content.fullscreen {
    // position: fixed;
    // top: 0;
    // left: 0;
    width: 100%;
    height: 90vh;
    // z-index: 9999;
    // background-color: white;
  }

  .sidebar {
    // height: 72.5vh;
    overflow: hidden;
    background: #2c2c2c;

    .icon {
      // width: 50px;
      width: 30px;
      // height: 50px;
      height: 30px;
    }

    .menu {
      // font-size: 3.5rem !important;
      font-size: 1.8rem !important;
      cursor: pointer;
      transition: all 0.3s;
    }

    .submenu {
      // font-size: 3rem !important;
      font-size: 1.8rem !important;
      cursor: pointer;
      transition: all 0.3s;
    }

    .menu:hover {
      background-color: #6b4032;
      border-radius: 100px;
    }

    .menu:focus {
      background-color: #6b4032;
      border-radius: 100px;
      border: none;
    }

    .menu:focus-visible {
      background-color: #6b4032;
      border-radius: 100px;
      border: none;
    }

    .menu.active {
      border-radius: 100px;
      background-color: var(--gray-opacity-2);
    }

    .submenu:hover {
      background-color: #6b4032;
      border-radius: 100px;
    }

    .submenu:focus {
      background-color: #6b4032;
      border-radius: 100px;
    }

    .submenu:focus-visible {
      background-color: #6b4032;
      border-radius: 100px;
    }

    .submenu.active {
      //background-color: #6B4032;
      border-radius: 100px;
      border: 2px solid #d66440;
    }

    .prev {
      font-size: 1.8rem !important;
      cursor: pointer;
      transition: all 0.3s;
    }

    .prev:hover {
      background-color: #6b4032;
      border-radius: 100px;
    }

    .prev:focus {
      background-color: #6b4032;
      border-radius: 100px;
    }

    .prev:focus-visible {
      background-color: #6b4032;
      border-radius: 100px;
    }
  }

  .footer {
    width: 100%;
    height: 17vh;
    max-height: 17vh;
    // overflow: hidden;
  }
}
