body {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

#meeting-grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(12, 1fr);
  /*gap: 10px; !* Optional: Adjust the gap between grid items *!*/
}

#meeting-grid .row > * {
  padding: 0 !important;
}

#meeting-grid .jitsiTrack {
  padding: 0 !important;
  aspect-ratio: 16/9;
  /* background-color: black; */
  /* object-fit: contain; */
}

#meeting-grid .video-container {
  position: relative;
  padding: 0 !important;
  aspect-ratio: initial;
  /*overflow: hidden;*/
}

/* 처음 렌더링 시 애니메이션 */
/* 스튜디오 */
#meeting-grid .jitsiTrack.animate-in {
  animation: fadeInOut01 1s ease-out;
  will-change: transform, opacity;
}
/* 경로당 */
#meeting-grid .video-container.animate-in {
  animation: fadeInOut02 1s ease-out;
  will-change: transform, opacity;
}

/* 경로당 화면 */
#meeting-grid .video-container video {
  object-fit: cover; /* 화면 사이즈 */
  animation: fadeInOut02 1s ease-out;
}

#meeting-grid .video-container .jitsiTrack {
  aspect-ratio: initial;
  /* background-color: black; */
}

#meeting-grid .video-container .participant-name {
  max-width: 95%;
  position: absolute;
  bottom: 10%;
  left: 0;
  background-color: #000000;
  font-size: 1rem;
  white-space: nowrap; /* 줄 바꿈 방지 */
  overflow: hidden; /* 넘치는 내용 숨김 */
  text-overflow: ellipsis; /* 초과 부분을 "..." 처리 */
  /*width: 200px; !* 특정 너비 지정 (필수) *!*/
}

/* 스튜디오 애니메이션 */
@keyframes fadeInOut01 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* 경로당 애니메이션 */
@keyframes fadeInOut02 {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
