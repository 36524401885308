.card {
  background-color: var(--surface);
  color: var(--on-surface);
}

.card-lg {
  /*padding: 30px;*/
  padding: 1.3rem;
  border-radius: var(--radius-28);
}

/*  */
.card-sm {
  /*padding: 20px;*/
  /*padding: 0.5rem 0.5rem;*/
  padding: 1rem;
  border-radius: var(--radius-16);
  display: grid;
  grid-template-rows: min-content 1fr;
  background-color: var(--white-opacity-4);
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin: 0 !important;
}